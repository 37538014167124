import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {type HospitalRoom} from '@modules/hospital_places/types';
import {SelectionDisplay} from '../SelectionDisplay';
import {useAtom, useAtomValue} from 'jotai';
import {chunk} from 'lodash';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {selectedReturnWaitingAreaInfoAtom, selectedReturnWaitingReceptionAtom} from '../states';
import {MAX_SELECTION_PER_PAGE, ReceptionSelectionMap} from '../consts';
import {getFloorDescription} from '../FloorSelection';

/**
 * 返却待ち受付 - 部屋選択画面
 */
export const ReturnWaitingReceptionRoomSelection = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [areaInfoAtom, setAreaInfoAtom] = useAtom(selectedReturnWaitingAreaInfoAtom);
  const [selectItem, setSelectItem] = useAtom(selectedReturnWaitingReceptionAtom);
  useEffect(() => {
    if (!selectItem.narrowCategoryName || !areaInfoAtom.ward) navigate(ReceptionSelectionMap.rootPath);
  }, []);

  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: areaInfoAtom.ward?.hashId,
    showRentalPlace: true,
    floorNumber: areaInfoAtom.floor?.floorNumber,
    isGroundFloor: areaInfoAtom.floor?.isGroundFloor,
    order: 'name',
  });

  const filteredRooms = useMemo(() => {
    const uniqueRooms = Array.from(
      roomData
        .reduce((map, room) => {
          const key = `${room.floorNumber}:${room.isGroundFloor}`;
          if (!map.has(key)) map.set(key, room);
          return map;
        }, new Map<string, HospitalRoom>())
        .values()
    );

    return chunk(uniqueRooms, MAX_SELECTION_PER_PAGE);
  }, [roomData]);

  const breadcrumbs = useMemo(
    () => [areaInfoAtom.ward?.name || '', areaInfoAtom.floor ? getFloorDescription(areaInfoAtom.floor) : ''],
    [areaInfoAtom]
  );

  const onSelect = (room: HospitalRoom) => {
    setSelectItem({...selectItem, roomHashId: room.hashId});
    setAreaInfoAtom({...areaInfoAtom, room});
    navigate(ReceptionSelectionMap.path.input);
  };

  return (
    <WardAssetMenuTemplate
      reservationDeviceName={selectItem?.narrowCategoryName}
      breadcrumbs={breadcrumbs}
      headerProps={{title: ReceptionSelectionMap.title}}
      footerProps={{
        text: ReceptionSelectionMap.footerTitle.selection,
        backButtonHandler: () => navigate(ReceptionSelectionMap.path.floorSelection),
      }}>
      <SelectionDisplay<HospitalRoom>
        data={filteredRooms}
        getLabel={(item) => item.name}
        onSelect={(item) => onSelect(item)}
      />
    </WardAssetMenuTemplate>
  );
};
