import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {createOrUpdateDashboardLayoutSettings, useDashboardLayoutSettingsQuery} from '@modules/dashboard/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import React, {useMemo} from 'react';
import GridLayout, {Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {papers} from './consts';
import {Header} from './Header';
import {PapersType} from './types';

const ResponsiveGridLayout = WidthProvider(Responsive);
export const ONE_HEIGHT_BOARDS = ['monthly_inspection_percent', 'yearly_inspection_percent'];
const TWO_HEIGHT_BOARDS = [
  'inspection_list',
  'rental_ratio',
  'non_returned_rental_products_table',
  'rental_history',
  'product_comment_list',
  'repair_comment_list',
];
const SIX_WIDTH_BOARDS = ['monthly_inspection_percent', 'yearly_inspection_percent', 'inspection_percent_by_product'];
const NON_RESIZABLE_BOARDS = ['monthly_inspection_percent', 'yearly_inspection_percent', 'rental_ratio'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
      width: '100%',
    },
    root: {
      overflow: 'auto',
    },
    layoutContainer: {
      margin: '0px 24px',
    },
  })
);

export const Dashboard: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {data, refetch} = useDashboardLayoutSettingsQuery(myInfo.hospitalHashId);

  const layouts = useMemo(
    () =>
      data?.map((boardLayout) => ({
        i: boardLayout.boardId,
        x: boardLayout.layout.x,
        y: boardLayout.layout.y,
        w: boardLayout.layout.width,
        h: boardLayout.layout.height,
        minW: 6,
        maxW: SIX_WIDTH_BOARDS.includes(boardLayout.boardId) ? 6 : 12,
        minH: TWO_HEIGHT_BOARDS.includes(boardLayout.boardId) ? 2 : 1,
        maxH: ONE_HEIGHT_BOARDS.includes(boardLayout.boardId) ? 1 : 2,
        // biome-ignore lint/complexity/noUselessTernary: <explanation>
        isResizable: NON_RESIZABLE_BOARDS.includes(boardLayout.boardId) ? false : true,
      })),
    [data]
  );

  const handleLayoutChange = async (currentLayout: GridLayout.Layout[]) => {
    const newLayout = currentLayout.map(({i, x, y, w, h}) => ({
      boardId: i as PapersType,
      layout: {width: w, height: h, x, y},
    }));
    try {
      await createOrUpdateDashboardLayoutSettings(myInfo.hospitalHashId, {layout: newLayout});
      refetch();
    } catch (_e) {
      return;
    }
  };

  return layouts === undefined ? null : (
    <ListPageLayout page="dashboard">
      <Grid className={classes.root}>
        <ListPageLayout.Header>
          <Header currentLayout={data === undefined ? [] : data} refetch={refetch} />
        </ListPageLayout.Header>
        <ListPageLayout.Content>
          <ResponsiveGridLayout
            className={classes.layoutContainer}
            layouts={{sm: layouts}}
            rowHeight={280}
            breakpoints={{sm: 768}}
            cols={{sm: 12}}
            draggableHandle=".dragHandle"
            onLayoutChange={handleLayoutChange}>
            {layouts.map((layout) => (
              <div key={layout.i}>
                {papers[layout.i.replace(/_([a-z])/g, (_match, letter) => letter.toUpperCase())]?.component}
              </div>
            ))}
          </ResponsiveGridLayout>
        </ListPageLayout.Content>
      </Grid>
    </ListPageLayout>
  );
};
