import React, {useEffect} from 'react';
import {Grid, SxProps, Theme} from '@mui/material';
import {Outlet, useNavigate} from 'react-router-dom';
import {withSuspense} from '@front-libs/core';
import {Helmet} from 'react-helmet-async';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {PermissionRouter} from '@permissionRouter';

export type ChildCommonProps = {
  onEnableNextLink: () => void;
  onDisableNextLink: () => void;
  onSetNextLink: (path: string) => void;
};

const _BaseSharedMenu: React.FC = () => {
  const {myInfo} = useMyInfo();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const navigate = useNavigate();

  // TODO:ここの判別不要のハズ
  useEffect(() => {
    if (!isNullish(data) && !data.initialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    if (!isNullish(myInfo.initialized) && !myInfo.initialized) {
      navigate('/signup/user', {replace: true});
      return;
    }
  }, [data, myInfo.initialized, navigate]);

  return (
    <PermissionRouter resource="LENDING_RETURN_RECEPTION">
      <Grid container sx={rootStyle}>
        <Helmet>
          <style>
            {`
              iframe {
                display: none ;
              }
          `}
          </style>
        </Helmet>
        <Outlet />
      </Grid>
    </PermissionRouter>
  );
};

export const BaseSharedMenu = withSuspense(_BaseSharedMenu);

const rootStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
  '@media (orientation: portrait)': {
    height: '100vw ',
    width: '100vh ',
    minHeight: '100vw ',
    transform: 'rotate(90deg)',
    position: 'absolute',
    top: 'calc((100vh - 100vw) / 2)',
    right: 'calc(0px - (100vh - 100vw) / 2)',
    overflow: 'auto',
  },
});
