import {NavLinkType} from '@components/organisms/Header/pc';
import {HospitalUserPermission} from '@modules/auth/consts';
import {useUserPermissions} from '@modules/auth/hooks';
import {useAccessibleResources} from '@modules/hospital_users/hooks/useUserPermissions';
import {PERMISSION_RESOURCES} from '@modules/hospital_users/types';
import React from 'react';

export const useHeaderNavLinkItems = () => {
  const accessibleResources = useAccessibleResources();
  const {isPermitted} = useUserPermissions();
  const items: NavLinkType[] = React.useMemo(
    () =>
      [
        {
          to: '/dashboard',
          label: 'ダッシュボード',
          hasPermission: accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.DASHBOARD.id),
        },
        {
          to: '/products',
          label: '機器一覧',
          hasPermission: accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.DEVICE_LIST.id),
        },
        {
          to: '/inspection_v2/results',
          label: '点検',
          hasPermission: accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.INSPECTION.id),
        },
        {
          to: '/repairs',
          label: '修理',
          hasPermission: accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.REPAIR.id),
        },
        {
          to: '/rentals',
          label: '貸出・返却',
          hasPermission: accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.RENTAL.id),
        },
        {
          to: '/training_managements/plans',
          label: '研修',
          childLinks: [
            {label: '研修計画マスタ', to: '/training_managements/plans'},
            {label: '研修（予定）', to: '/training_managements/schedules'},
            {label: '研修（実施済み）', to: '/training_managements/reports'},
          ],
          hasPermission:
            isPermitted([HospitalUserPermission.TrainingManagement]) &&
            accessibleResources.some((v) => v.resource.name === PERMISSION_RESOURCES.TRAINING.id),
        },
      ].filter((item) => item !== null && item.hasPermission) as NavLinkType[], // nullをフィルタリングして型を修正
    [accessibleResources, isPermitted]
  );
  return items;
};
