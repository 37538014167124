import React, {useEffect} from 'react';
import {Grid, Theme, styled} from '@material-ui/core';
import {Outlet, useNavigate} from 'react-router-dom';
import {withSuspense} from '@front-libs/core';
import {Helmet} from 'react-helmet-async';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {PermissionRouter} from '@permissionRouter';

export type ChildCommonProps = {
  onEnableNextLink: () => void;
  onDisableNextLink: () => void;
  onSetNextLink: (path: string) => void;
};

/**
 *
 * @todo Mui V4とV5の混在でスタイルが適用されないことがあるのでMuiV4のBaseを一旦親とする。 MuiV5統一時にBaseWardMenuV5と入れ替える
 */
const _BaseWardMenu: React.FC = () => {
  const {myInfo} = useMyInfo();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNullish(data) && !data.initialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    if (!isNullish(myInfo.initialized) && !myInfo.initialized) {
      navigate('/signup/user', {replace: true});
      return;
    }
  }, [data, myInfo.initialized, navigate]);

  return (
    <PermissionRouter resource="WARD_SERVICE">
      <StyledGrid>
        <Helmet>
          <style>
            {`
              iframe {
                display: none !important;
              }
          `}
          </style>
        </Helmet>
        <Outlet />
      </StyledGrid>
    </PermissionRouter>
  );
};

export const BaseWardMenu = withSuspense(_BaseWardMenu);

const StyledGrid = styled(Grid)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
}));
