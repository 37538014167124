import {theme} from '@components/atoms/theme';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {NestedMenuList, NestedMenuListItemType} from '@components/molecules/NestedMenuList/NestedMenuList';
import {ProductStatus} from '@components/molecules/ProductStatus';
import {WholeProductEditDialogResult} from '@components/organisms/WholeProductEditDialog/type';
import {getHospitalProductKey, getHospitalProductFilesKey, getInspectionResultsKey} from '@constants/api';
import {useBackPrevious} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {deleteHospitalProduct, patchHospitalProduct} from '@modules/hospital_products/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {createWholeProduct} from '@modules/products/api';
import {CreateWholeProduct} from '@modules/products/types';
import {openSnackBar} from '@molecules/SnackBar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {styled, Typography} from '@mui/material';
import {WholeProductEditDialog, WholeProductEditDialogProps} from '@organisms/WholeProductEditDialog/index';
import React from 'react';
import {useQueryClient} from 'react-query';
import {RelinkAddDeleteMenuListItem, RelinkMenuListItems} from './constants';
import {openConfirmRelinkDialog} from './dialog/ConfirmRelinkDialog';
import {InspectionDeleteDialog, InspectionDeleteDialogProps} from './dialog/InspectionDeleteDialog';
import {SelectRelinkDeviceDialog, SelectRelinkDeviceDialogProps} from './dialog/SelectRelinkDeviceDialog';
import {DeviceSplitMenuValueType} from './type';
import {RelinkConfirmationMessage} from './dialog/RelinkConfirmationMessage';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const ContentsArea = styled('div')({
  backgroundColor: 'white',
  width: 'calc(100% - 128px)',
  height: '44px',
  paddingLeft: '136px',
  borderBottom: '1px solid #D1D5DB',
  borderRadius: '20px 20px 0 0',
});

const ContentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

const HospitalProductInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ManagementID = styled(Typography)({
  paddingLeft: '16px',
  fontWeight: 'bold',
});
const DisplayName = styled(Typography)({
  paddingLeft: '16px',
  fontWeight: 'bold',
});

type ProductDetailHeaderProps = {
  hospitalProduct: HospitalProductDetail;
  /** 機器情報を複製して新規登録ボタン押下時 */
  onCopyProduct: () => void;
  onDeleteProduct?: () => Promise<void>;
};

/** 再紐付けする院内機種を選択 ダイアログ呼び出し */
const handelSelectRelinkDevice = async (wholeProductHashId: string) => {
  try {
    return await dialogHandler.open<SelectRelinkDeviceDialogProps, string>(SelectRelinkDeviceDialog, {
      wholeProductHashId: wholeProductHashId,
    });
  } catch (_e) {
    return null;
  }
};

/** 再紐付けする機種情報ダイアログ処理 */
const handleWholeProductEditDialogResult = async (hospitalHashId: string) => {
  try {
    const wholeProductEditDialogResult = await dialogHandler.open<
      WholeProductEditDialogProps,
      WholeProductEditDialogResult
    >(WholeProductEditDialog, {
      title: '再紐付けする機種情報の入力',
      content: <RelinkConfirmationMessage messageStyle={{minWidth: '630px', marginTop: '-32px'}} />,
      paperStyle: {minWidth: '696px'},
    });
    const isNew = !!wholeProductEditDialogResult.newMakerName;
    const newProduct: CreateWholeProduct = {
      ...wholeProductEditDialogResult,
      makerHashID: isNew ? undefined : wholeProductEditDialogResult.makerHashId,
      makerName: isNew ? wholeProductEditDialogResult.newMakerName : undefined,
    };
    try {
      const res = await createWholeProduct(hospitalHashId, newProduct);
      return res.data.hashId;
    } catch (e) {
      console.error(e);
      openSnackBar('機種の追加登録に失敗しました。', 'left', 'bottom', 'error');
      return null;
    }
  } catch (_e) {
    return null;
  }
};

type MoreVertButtonProps = {
  actionMenuItems: NestedMenuListItemType<DeviceSplitMenuValueType>[];
  onMenuClick: (item: NestedMenuListItemType<DeviceSplitMenuValueType>) => void;
};
/** 三点リーダーボタン */
const MoreVertButton = ({actionMenuItems, onMenuClick}: MoreVertButtonProps) => {
  return (
    <NestedMenuList menuItemList={actionMenuItems} onMenuClick={onMenuClick}>
      <MoreVertIcon sx={{color: theme.palette.common.black, backgroundColor: 'white'}} />
    </NestedMenuList>
  );
};

type EllipsisButtonProps = {
  isAdmin: boolean;
  isReadOnly: boolean;
  onMenuClick: (item: NestedMenuListItemType<DeviceSplitMenuValueType>) => void;
};
/** 管理者権限の有無でボタンの出し分けを行うコンポーネント */
const RoleBasedButton = ({isReadOnly, isAdmin, onMenuClick}: EllipsisButtonProps) => {
  if (isReadOnly) return null;

  // 管理者権限のみ削除ボタン追加
  const menuItem = isAdmin ? RelinkAddDeleteMenuListItem : RelinkMenuListItems;
  return <MoreVertButton onMenuClick={onMenuClick} actionMenuItems={menuItem} />;
};

/**
 * 機器詳細情報のHeader部分
 * 構成要素：ステータス、管理ID、機器名、ポッパーメニューボタン
 */
export const ProductDetailHeader: React.FC<ProductDetailHeaderProps> = (props) => {
  const {canEdit: canEditDeviceList, canDelete: canDeleteDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  const {hospitalProduct, onCopyProduct} = props;
  const {myInfo} = useMyInfo();
  const backPrevious = useBackPrevious('/products');
  const queryClient = useQueryClient();

  /** 削除ボタン押下時 */
  const handleClickDelete = async () => {
    try {
      await dialogHandler.open<InspectionDeleteDialogProps, unknown>(InspectionDeleteDialog, {
        userName: myInfo.username,
      });
      handleDeleteProduct();
    } catch (_e) {
      // empty
    }
  };

  /** 削除処理 */
  const handleDeleteProduct = async () => {
    if (isNullish(hospitalProduct)) return;
    try {
      await deleteHospitalProduct(myInfo.hospitalHashId, hospitalProduct.hashId);
      openSnackBar('機器を削除しました');
      backPrevious();
    } catch (_e) {
      openSnackBar('機器の削除に失敗しました', 'left', 'bottom', 'error');
    }
  };

  /** 「登録済みの院内機種に再紐付け」押下時 */
  const handleRegistered = async () => {
    const wholeProductHashId = await handelSelectRelinkDevice(hospitalProduct.wholeProductHashId);
    if (!wholeProductHashId) return;

    handleRelinkProduct(wholeProductHashId);
  };

  /** 「院内で未登録の機種に再紐付け」項目押下時 */
  const handleClickUnregistered = async () => {
    const wholeProductHashId = await handleWholeProductEditDialogResult(myInfo.hospitalHashId);
    if (wholeProductHashId === null) return;

    handleRelinkProduct(wholeProductHashId);
  };

  /**
   * 再紐付けの実行
   * @param wholeProductHashId
   * @returns
   */
  const handleRelinkProduct = async (wholeProductHashId: string) => {
    const result = await openConfirmRelinkDialog(myInfo.hospitalHashId, hospitalProduct.wholeProductHashId);
    if (!result) return;

    try {
      await patchHospitalProduct(myInfo.hospitalHashId, hospitalProduct.hashId, wholeProductHashId);
      // 再紐付け後、関連するデータを再取得する
      queryClient.invalidateQueries({
        queryKey: [getHospitalProductKey, myInfo.hospitalHashId, hospitalProduct.hashId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useFetchMakerInspectionSettingsQuery', myInfo.hospitalHashId, hospitalProduct.hashId],
      });
      queryClient.invalidateQueries({
        queryKey: [getHospitalProductFilesKey, myInfo.hospitalHashId, hospitalProduct.hashId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          getInspectionResultsKey,
          myInfo.hospitalHashId,
          'inspectionHashId',
          {
            hospitalProductHashId: hospitalProduct.hashId,
            statuses: 'unplanned,uncompleted',
            order: 'scheduledTime',
          },
        ],
      });
      openSnackBar('機種を再紐付けしました。');
    } catch (_e) {
      openSnackBar('機種の再紐付けに失敗しました。', 'left', 'bottom', 'error');
    }
  };
  /** メニューリストアイテムクリック時 */
  const handleActionMenuClick = (item: NestedMenuListItemType<DeviceSplitMenuValueType>) => {
    switch (item.value) {
      case 'copy':
        onCopyProduct();
        break;
      case 'delete':
        handleClickDelete();
        break;
      case 'registered':
        handleRegistered();
        break;
      case 'unregistered':
        handleClickUnregistered();
        break;
      default:
        break;
    }
  };

  return (
    <ContentsArea>
      <ContentContainer>
        <HospitalProductInfo>
          <ProductStatus productStatus={hospitalProduct.status} marginTop={'0px'} />
          <ManagementID>{hospitalProduct.managementId}</ManagementID>
          <DisplayName>{hospitalProduct.displayName}</DisplayName>
        </HospitalProductInfo>
        <RoleBasedButton
          isAdmin={canDeleteDeviceList}
          isReadOnly={!canEditDeviceList}
          onMenuClick={handleActionMenuClick}
        />
      </ContentContainer>
    </ContentsArea>
  );
};
