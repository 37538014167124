import React from 'react';
import {Navigate, RouteObject} from 'react-router-dom';
import {BaseProductManager} from '@Apps/BaseProductManger';
import {ProductsList} from '@Apps/ProductsList/pc';
import {WholeProductsList} from '@Apps/WholeProductList';
import {Step2} from '@Apps/ProductsList/pc/ProductsRegistrationDialog/Step2/Step2';
import {ProductDetail} from '@Apps/ProductDetail';
import {RentalList} from '@Apps/RentalList';
import {BaseSharedMenu} from '@Apps/BaseSharedMenu';
import {RentOrReturn} from '@Apps/BaseSharedMenu/RentOrReturn';
import {LenderSelection} from '@Apps/BaseSharedMenu/LenderSelection';
import {WardSelection} from '@Apps/BaseSharedMenu/WardSelection';
import {FloorSelection} from '@Apps/BaseSharedMenu/FloorSelection';
import {RoomSelection} from '@Apps/BaseSharedMenu/RoomSelection';
import {RentByBarcodeReader} from '@Apps/BaseSharedMenu/RentDevices/RentByBarcodeReader';
import {ConfirmScanRentDevice} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ConfirmScan';
import {ScanRentDeviceBarcode} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ScanBarcode';
import {ConfirmCheckoutRentDevice} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ConfirmCheckout';
import {ReturnByBarcodeReader} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByBarcodeReader';
import {ConfirmScanReturnDevice} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ConfirmScan';
import {ScanReturnDeviceBarcode} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ScanBarcode';
import {ConfirmReturn} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ConfirmReturn';
import {Complete} from '@Apps/BaseSharedMenu/Complete';
import {CardsPositionSetting} from '@Apps/BaseSharedMenu/CardsPositionSetting';
import {Dashboard} from '@Apps/Dashboard';
import {Channel} from '@Apps/Channel';
import {SettingsHospitalRental} from '@Apps/Settings/Rental';
import {SettingsHospitalInspection} from '@Apps/Settings/Inspection';
import {SettingsHospitalInfo} from '@Apps/Settings/Hospital';
import {SettingsHospitalPlace} from '@Apps/Settings/Place';
import {SettingsHospitalUser} from '@Apps/Settings/User';
import {AccountProfile} from '@Apps/Account/Profile';
import {AccountNotificationSetting} from '@Apps/Account/NotificationSetting';
import {AccountStatus} from '@Apps/Account/Status';
import {AccountHospitalDocument} from '@Apps/Account/Document';
import {InspectionEditor} from '@Apps/InspectionSettings/InspectionEditor';
import {InspectionResult} from '@Apps/InspectionResult/pc';
import {RepairList} from '@Apps/RepairList';
import {RepairDetail} from '@Apps/RepairDetail';
import {UserSignUp} from './Apps/UserSignUp';
import {HospitalSignUp} from './Apps/HospitalSignUp';
import {ProductRegistration} from '@Apps/ProductRegistration';
import {ProductImport} from './Apps/ProductImport';
import {TrainingManagementPlan} from '@Apps/TrainingManagementPlans';
import {TrainingManagementSchedules} from './Apps/TrainingManagementSchedules';
import {MakerInspectionResult} from './Apps/MakerInspectionResult';
import {TrainingManagementScheduleDetail} from '@Apps/TrainingManagementScheduleDetail';
import {TrainingManagementReports} from './Apps/TrainingManagementReports';
import {InspectionTemplates} from '@Apps/InspectionTemplates';
import {GeneralFileList} from './Apps/GeneralFileList';
import {ManagementDashboard} from './Apps/ManagementDashboard';
import {RentalRatio} from './Apps/ManagementDashboard/RentalRatio';
import {CostManagement} from './Apps/ManagementDashboard/CostManagement';
import {FaultRatio} from './Apps/ManagementDashboard/FaultRatio';
import {SettingsHospitalRepair} from '@Apps/Settings/Repair';
import {SettingsManagementDepartment} from '@Apps/Settings/Management';
import {SettingsDealer} from '@Apps/Settings/Dealer/index';
import {FaultReceptions} from '@Apps/FaultReceptions';
import {FaultReceptionInput} from './Apps/FaultReception';
import {FaultReceptionPreview} from './Apps/BaseSharedMenu/FaultReceptionPreview';
import {FaultComplete} from '@Apps/BaseSharedMenu/FaultComplete';
import {FaultReceptionDetail} from '@Apps/FaultReceptionDetail';
import {ReceptionMenu} from '@Apps/BaseSharedMenu/ReceptionMenu';
import {FaultReceptionHistory} from '@Apps/BaseSharedMenu/FaultReceptionHistory';
import {BaseWardMenu} from '@Apps/BaseWardMenu';
import {FaultReceptionHistoryDetail} from './Apps/BaseSharedMenu/FaultReceptionHistoryInfo';
import {SettingsHospitalProductManagement} from './Apps/Settings/ProductManagement';
import {SettingsHospitalProductRegistration} from './Apps/Settings/ProductRegistration';
import {RentableDevices} from './Apps/RentableDevices';
import {ProductBulkUpdates} from './Apps/ProductBulkUpdates';
import {InspectionTableList} from './Apps/InspectionSettings/InspectionTableList';
import {HospitalProductPlanList} from './Apps/InspectionSettings/HospitalProductPlanList';
import {WholeProductPlans} from './Apps/InspectionSettings/WholeProductPlanList';
import {CreateWholeProductPlanList} from './Apps/InspectionSettings/WholeProductPlanList/CreateWholeProductPlanList';
import {InspectionResultsList as InspectionResultsList2} from './Apps/InspectionResultList/pc';
import {InspectionPreview} from './Apps/InspectionSettings/InspectionPreview';
import {EditWholeProductPlanList} from './Apps/InspectionSettings/WholeProductPlanList/EditWholeProductPlanList';
import {WholeProductUnregisteredSearch} from '@Apps/WholeProduct/WholeProductUnregisteredSearch';
import {SettingsFaultReception} from './Apps/Settings/FaultReception';
import {WholeProductDetail} from '@Apps/WholeProduct/WholeProductDetail';
import {ProductChildDeviceEditor} from './Apps/ProductChildDeviceEditor';
import {ProductsParts} from './Apps/ProductsParts';
import {ReturnWaitingReception} from '@Apps/BaseSharedMenu/ReturnWaitingReception';
import {ReturnWaitingReceptionWardSelection} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionWardSelection';
import {ReturnWaitingReceptionFloorSelection} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionFloorSelection';
import {ReturnWaitingReceptionRoomSelection} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionRoomSelection';
import {ReturnWaitingReceptionComplete} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionComplete';
import {ReturnWaitingReceptionInput} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionInput';
import {ReturnWaitingReceptionPreview} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionPreview';
import {ReturnWaitingReceptionList} from '@Apps/ReturnWaitingReceptionList';
import {ReturnWaitingReceptionHistory} from '@Apps/BaseSharedMenu/ReturnWaitingReceptionHistory';
import {PermissionRouter} from '@permissionRouter';

export type RouteObjectAddKey = RouteObject & {
  key?: string;
  children?: RouteObjectAddKey[];
};

// NOTE:PermissionRouterはBaseSharedMenuに設定
export const rentalRoutes = {
  element: <BaseSharedMenu />,
  children: [
    {
      key: 'rentOrReturn',
      exact: true,
      path: '/shared',
      element: <RentOrReturn />,
    },
    {
      key: 'lenderSelection',
      exact: true,
      path: '/shared/lenderSelection',
      element: <LenderSelection />,
    },
    {
      key: 'wardSelection',
      exact: true,
      path: '/shared/rental/wardSelection',
      element: <WardSelection />,
    },
    {
      key: 'floorSelection',
      exact: true,
      path: '/shared/rental/floorSelection',
      element: <FloorSelection />,
    },
    {
      key: 'roomSelection',
      exact: true,
      path: '/shared/rental/roomSelection',
      element: <RoomSelection />,
    },
    {
      key: 'rentProductByBarcodeReader',
      exact: true,
      path: '/shared/rental/product/barcode_reader',
      element: <RentByBarcodeReader />,
    },
    {
      key: 'confirmScanByCamera',
      exact: true,
      path: '/shared/rental/product/camera',
      element: <ConfirmScanRentDevice />,
    },
    {
      key: 'scanBarcodeByCamera',
      exact: true,
      path: '/shared/rental/product/camera/scan',
      element: <ScanRentDeviceBarcode />,
    },
    {
      key: 'confirmCheckout',
      exact: true,
      path: '/shared/rental/product/camera/checkout',
      element: <ConfirmCheckoutRentDevice />,
    },
    {
      key: 'returnProductByBarcodeReader',
      exact: true,
      path: '/shared/return/product/barcode_reader',
      element: <ReturnByBarcodeReader />,
    },
    {
      key: 'confirmScanReturnDevice',
      exact: true,
      path: '/shared/return/product/camera',
      element: <ConfirmScanReturnDevice />,
    },
    {
      key: 'scanReturnDeviceBarcode',
      exact: true,
      path: '/shared/return/product/camera/scan',
      element: <ScanReturnDeviceBarcode />,
    },
    {
      key: 'confirmReturn',
      exact: true,
      path: '/shared/return/product/camera/checkout',
      element: <ConfirmReturn />,
    },
    {
      key: 'complete',
      exact: true,
      path: '/shared/complete',
      element: <Complete />,
    },
    {
      key: 'cardsPositionSetting',
      exact: true,
      path: '/shared/cards_position_setting',
      element: <CardsPositionSetting />,
    },
  ],
};
// 病棟ユーザーメニュー NOTE:PermissionRouterはBaseWardMenuに設定
export const wardMenuRoutes: RouteObjectAddKey = {
  path: '',
  element: <BaseWardMenu />,
  children: [
    {
      key: 'faultReception',
      expect: true,
      path: '/shared/faut_reception/register/input',
      element: <FaultReceptionInput />,
    },
    {
      key: 'preview',
      expect: true,
      path: '/shared/faut_reception/register/preview',
      element: <FaultReceptionPreview />,
    },
    {
      key: 'faultComplete',
      expect: true,
      path: '/shared/faut_reception/register/complete/:hashId',
      element: <FaultComplete />,
    },
    {
      key: 'receptionMenu',
      expect: true,
      path: '/shared/reception_menu',
      element: <ReceptionMenu />,
    },
    {
      key: 'fault_reception_history',
      expect: true,
      path: '/shared/fault_receptions',
      element: <FaultReceptionHistory />,
    },
    {
      key: 'fault_reception_detail',
      path: '/shared/fault_receptions/:hashId',
      element: <FaultReceptionHistoryDetail />,
    },
    {
      key: 'rentable_devices',
      path: '/shared/rentable_devices',
      element: <RentableDevices />,
    },
    {
      key: 'return_waiting_reception',
      path: '/shared/return_waiting_reception',
      element: <ReturnWaitingReception />,
    },
    {
      key: 'return_waiting_reception_ward_selection',
      path: '/shared/return_waiting_reception/ward_selection',
      element: <ReturnWaitingReceptionWardSelection />,
    },
    {
      key: 'return_waiting_reception_floor_selection',
      path: '/shared/return_waiting_reception/floor_selection',
      element: <ReturnWaitingReceptionFloorSelection />,
    },
    {
      key: 'return_waiting_reception_room_selection',
      path: '/shared/return_waiting_reception/room_selection',
      element: <ReturnWaitingReceptionRoomSelection />,
    },
    {
      key: ' return_waiting_reception_input',
      path: '/shared/return_waiting_reception/register/input',
      element: <ReturnWaitingReceptionInput />,
    },
    {
      key: 'return_waiting_reception_preview',
      path: '/shared/return_waiting_reception/register/preview',
      element: <ReturnWaitingReceptionPreview />,
    },
    {
      key: 'return_waiting_reception_complete',
      path: '/shared/return_waiting_reception/register/complete',
      element: <ReturnWaitingReceptionComplete />,
    },
    {
      key: 'fault_reception_history',
      expect: true,
      path: '/shared/return_waiting_reception/history',
      element: <ReturnWaitingReceptionHistory />,
    },
  ],
};

export const nonHeaderRoutes = {
  path: '/',
  element: <BaseProductManager showHeader={false} />,
  children: [
    {
      key: 'productChildDeviceEditor',
      path: '/products/:hashId/parent/edit_children',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <ProductChildDeviceEditor />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspection_v2_table_preview',
      path: '/inspection_v2/table_preview/:hashId',
      element: (
        <PermissionRouter resource="INSPECTION" requireEdit>
          <InspectionPreview />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspectionDetail',
      path: '/inspections/:hashId',
      element: (
        <PermissionRouter resource="INSPECTION" requireEdit>
          <InspectionEditor />
        </PermissionRouter>
      ),
    },
    {
      key: 'productRegistration',
      path: '/products/registration',
      element: (
        <PermissionRouter resource="PRODUCT_LIST" requireEdit>
          <ProductRegistration />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspectionV2WholeProductPlansCreate',
      path: '/inspection_v2/whole_product_plans/:wholeProductHashId/create',
      element: (
        <PermissionRouter resource="INSPECTION" requireEdit>
          <CreateWholeProductPlanList />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspectionV2WholeProductPlansEdit',
      path: '/inspection_v2/whole_product_plans/:wholeProductHashId/edit',
      element: (
        <PermissionRouter resource="INSPECTION" requireEdit>
          <EditWholeProductPlanList />
        </PermissionRouter>
      ),
    },
    {
      key: 'wholeProductSearch',
      path: '/products/whole_products/search',
      element: (
        <PermissionRouter resource="PRODUCT_LIST" requireEdit>
          <WholeProductUnregisteredSearch />
        </PermissionRouter>
      ),
    },
  ],
};

export const mainRoutes = {
  path: '/',
  element: <BaseProductManager showHeader={true} />,
  children: [
    {
      key: 'inspection_v2_tables',
      path: '/inspection_v2/tables',
      element: (
        <PermissionRouter resource="INSPECTION">
          <InspectionTableList />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspection_v2_whole_product_plans',
      path: '/inspection_v2/whole_product_plans',
      element: (
        <PermissionRouter resource="INSPECTION">
          <WholeProductPlans />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspection_v2_hospital_product_plans',
      path: '/inspection_v2/hospital_product_plans',
      element: (
        <PermissionRouter resource="INSPECTION">
          <HospitalProductPlanList />
        </PermissionRouter>
      ),
    },
    {
      key: 'hospitalSignUp',
      path: '/signup/hospital',
      element: <HospitalSignUp />,
    },
    {
      key: 'userSignUp',
      path: '/signup/user',
      element: <UserSignUp />,
    },
    {
      key: 'home',
      path: '/',
      element: (
        <PermissionRouter resource="DASHBOARD">
          <Navigate to="/dashboard" />
        </PermissionRouter>
      ),
    },
    {
      key: 'dashboard',
      path: '/dashboard',
      element: (
        <PermissionRouter resource="DASHBOARD">
          <Dashboard />
        </PermissionRouter>
      ),
    },
    {
      key: 'channel',
      path: '/channels',
      element: (
        <PermissionRouter resource="CHANNEL_LIST">
          <Channel />
        </PermissionRouter>
      ),
    },
    {
      key: 'products',
      path: '/products',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <ProductsList />
        </PermissionRouter>
      ),
    },
    {
      key: 'productDetail',
      path: '/products/:hashId',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <ProductDetail />
        </PermissionRouter>
      ),
    },
    {
      key: 'wholeProducts',
      path: '/products/whole_products',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <WholeProductsList />
        </PermissionRouter>
      ),
    },
    {
      key: 'wholeProductsDetail',
      path: '/products/whole_products/:hashId',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <WholeProductDetail />
        </PermissionRouter>
      ),
    },
    {
      key: 'productsRegistration',
      path: '/products/registration/Step2/:hashId',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <Step2 />
        </PermissionRouter>
      ),
    },
    {
      key: 'productsImport',
      path: '/products/registration/import',
      element: (
        <PermissionRouter resource="PRODUCT_LIST" requireEdit>
          <ProductImport />
        </PermissionRouter>
      ),
    },
    {
      key: 'productsBulkUpdates',
      path: '/products/bulk-updates',
      element: (
        <PermissionRouter resource="PRODUCT_LIST" requireEdit>
          <ProductBulkUpdates />
        </PermissionRouter>
      ),
    },
    {
      key: 'ProductsParts',
      path: '/products/parts',
      element: (
        <PermissionRouter resource="PRODUCT_LIST">
          <ProductsParts />
        </PermissionRouter>
      ),
    },
    {
      key: 'InspectionResultsList2',
      path: '/inspection_v2/results/',
      element: (
        <PermissionRouter resource="INSPECTION">
          <InspectionResultsList2 />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspectionResult',
      path: '/inspections/:inspectionHashId/result/:inspectionResultHashId',
      element: (
        <PermissionRouter resource="INSPECTION">
          <InspectionResult />
        </PermissionRouter>
      ),
    },
    {
      key: 'inspectionTemplates',
      path: '/inspection/templates',
      element: (
        <PermissionRouter resource="INSPECTION" requireEdit>
          <InspectionTemplates />
        </PermissionRouter>
      ),
    },
    {
      key: 'makerInspectionResult',
      path: '/maker_inspections/results/:hashId',
      element: (
        <PermissionRouter resource="INSPECTION">
          <MakerInspectionResult />
        </PermissionRouter>
      ),
    },
    {
      key: 'rentals',
      path: '/rentals',
      element: (
        <PermissionRouter resource="RENTAL">
          <RentalList />
        </PermissionRouter>
      ),
    },
    {
      key: 'rentalsReturnWaitingReception',
      path: '/rentals/return_waiting_reception',
      element: (
        <PermissionRouter resource="RENTAL">
          <ReturnWaitingReceptionList />
        </PermissionRouter>
      ),
    },
    {
      key: 'repairs',
      path: '/repairs',
      element: (
        <PermissionRouter resource="REPAIR">
          <RepairList />
        </PermissionRouter>
      ),
    },
    {
      key: 'repairDetail',
      path: '/repairs/:hashId',
      element: <RepairDetail />,
    },
    {
      key: 'settingsHospitalRental',
      path: '/settings/rental',
      element: <SettingsHospitalRental />,
    },
    {
      key: 'settingsHospitalInspection',
      path: '/settings/inspection',
      element: <SettingsHospitalInspection />,
    },
    {
      key: 'settingsHospitalRepair',
      path: '/settings/repair',
      element: <SettingsHospitalRepair />,
    },
    {
      key: 'settingsHospitalProductManagement',
      path: '/settings/product_management',
      element: <SettingsHospitalProductManagement />,
    },
    {
      key: 'settingsHospitalProductRegistration',
      path: '/settings/product_registration',
      element: <SettingsHospitalProductRegistration />,
    },
    {
      key: 'settingsHospitalInfo',
      path: '/settings/hospital',
      element: <SettingsHospitalInfo />,
    },
    {
      key: 'settingsHospitalPlace',
      path: '/settings/place',
      element: <SettingsHospitalPlace />,
    },
    {
      key: 'settingsHospitalUser',
      path: '/settings/user',
      element: <SettingsHospitalUser />,
    },
    {
      key: 'settingsManagementDepartment',
      path: '/settings/management',
      element: <SettingsManagementDepartment />,
    },
    {
      key: 'settingsHospitalDealer',
      path: '/settings/dealer',
      element: <SettingsDealer />,
    },
    {
      key: 'settingsFaultReception',
      path: '/settings/fault_reception',
      element: <SettingsFaultReception />,
    },
    {
      key: 'accountProfile',
      path: '/account/profile',
      element: <AccountProfile />,
    },
    {
      key: 'accountNotificationSetting',
      path: '/account/notification',
      element: <AccountNotificationSetting />,
    },
    {
      key: 'accountStatus',
      path: '/account/status',
      element: <AccountStatus />,
    },
    {
      key: 'accountHospitalDocument',
      path: '/account/document',
      element: <AccountHospitalDocument />,
    },
    {
      key: 'trainingManagementPlan',
      path: '/training_managements/plans',
      element: (
        <PermissionRouter resource="TRAINING">
          <TrainingManagementPlan />
        </PermissionRouter>
      ),
    },
    {
      key: 'trainingManagementSchedule',
      path: '/training_managements/schedules',
      element: (
        <PermissionRouter resource="TRAINING">
          <TrainingManagementSchedules />
        </PermissionRouter>
      ),
    },
    {
      key: 'trainingManagementScheduleDetail',
      path: '/training_managements/schedules/:hashId',
      element: (
        <PermissionRouter resource="TRAINING">
          <TrainingManagementScheduleDetail />
        </PermissionRouter>
      ),
    },
    {
      key: 'trainingManagementReports',
      path: '/training_managements/reports',
      element: (
        <PermissionRouter resource="TRAINING">
          <TrainingManagementReports />
        </PermissionRouter>
      ),
    },
    {
      key: 'generalFileList',
      path: '/general_menu/file_list',
      element: <GeneralFileList />,
    },
    // TODO:経営ダッシュボードは別途対応
    {
      key: 'managementDashboard',
      path: '/general_menu/management_dashboard',
      element: <ManagementDashboard />,
      children: [
        {
          key: 'costManagement',
          exact: true,
          path: '/general_menu/management_dashboard/cost_management',
          element: <CostManagement />,
        },
        {
          key: 'rentalRatio',
          exact: true,
          path: '/general_menu/management_dashboard/rental_ratio',
          element: <RentalRatio />,
        },
        {
          key: 'faultRatio',
          exact: true,
          path: '/general_menu/management_dashboard/repair_ratio',
          element: <FaultRatio />,
        },
      ],
    },
    {
      key: 'fault_receptions',
      path: '/repairs/fault_receptions',
      element: (
        <PermissionRouter resource="REPAIR">
          <FaultReceptions />
        </PermissionRouter>
      ),
    },
    {
      key: 'fault_reception_detail',
      path: '/repairs/fault_receptions/:hashId',
      element: (
        <PermissionRouter resource="REPAIR">
          <FaultReceptionDetail />
        </PermissionRouter>
      ),
    },
    // TODO:確認して不要であれば削除
    {
      key: 'FaultReception',
      path: '/defect',
    },
  ],
};
