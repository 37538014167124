import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, createStyles, Grid, makeStyles, TextField, Theme, Typography, useMediaQuery} from '@material-ui/core';
import {ArrowDropDown, Search, Tune} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {Pagination} from '@material-ui/lab';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useDebounceCallback} from '@front-libs/core';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useNavigate, useNavigation} from 'react-router-dom';
import {openSnackBar} from '@molecules/SnackBar';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {Column} from '@molecules/Table/props';
import {bulkUpdateRentals, requestExportRentals, useFetchRentalsQuery} from '@modules/rentals/api';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {fetchHospitalWards} from '@modules/hospital_wards/api';
import {useQuery} from 'react-query';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import dayjs from 'dayjs';
import {AssignmentReturned} from '@material-ui/icons';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {TermCustom, TermPicker} from '@molecules/TermPicker';
import {
  checkoutAtAtom,
  orderKeyAtom,
  pageAtom,
  pageSizeAtom,
  rentalHistoryMenuStatus,
  rentalHistorySelectedWardStatus,
  rentalVariablesAtom,
  returnedAtAtom,
  searchFilterResultsAtom,
  searchNameAtom,
} from './states';
import {useAtom, useAtomValue} from 'jotai';
import {convertDateToRFC3339, convertDateToSimpleDateTime, isNullish} from '@front-libs/helpers';
import {RentalHistoryMenuItemType} from './types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {Table} from '@molecules/Table';
import {useSetAtom} from 'jotai';
import {BulkReturnRentalProductsDialog} from './BulkReturnRentalProductsDialog';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {HospitalRentalSetting, rentalSettings} from '@modules/hospital_settings/types';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {FilterDrawer} from '@molecules/Drawers/FilterDrawer';
import {FilterOption, ResultType} from '@molecules/Drawers/FilterDrawer/types';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useIsDetailFilterActive} from './hooks';
import {TermOptions} from './consts';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {CreateRentalsDatePickerDialog} from '@components/molecules/Dialogs/RentalsDatePickerDialog';
import {RequestExportRental} from '@modules/rentals/type';
import {MoveTargetBuildingSVG} from '@components/atoms/Icons/moveTargetBuildingSVG';
import {BulkMoveBuildingDialog} from '../ProductsList/pc/Dialogs/BulkMoveBuildingDialog';
import {bulkCreateMoveTargetBuildings} from '@modules/move_target_buildings/api';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {CountOutlinedButton} from '@components/molecules/Buttons/CountOutlinedButton';
import {useReturnWaitingReceptionsCount} from '@modules/returns_reservation/hook';

const actionMenuItems = [
  {
    label: 'エクスポート',
    value: 'export',
  },
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];
const rentalHistoryMenuItems = [
  {
    label: '貸出中機器一覧',
    value: 'renting',
  },
  {
    label: '貸出・返却履歴',
    value: '',
  },
  {
    label: '返却履歴',
    value: 'returned',
  },
];

const defaultWard = {
  label: 'すべての大エリア',
  value: '',
};

const useTermPicker = (atom: typeof checkoutAtAtom | typeof returnedAtAtom) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useAtom(atom);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSelectType = useCallback(
    (value: (typeof TermOptions)[number]['value']) => {
      setDateRange({value});

      if (value !== TermCustom) {
        setOpen(false);
      }
    },
    [setDateRange]
  );

  const onChangeFrom = useCallback(
    (from: Date | null) => {
      let shouldClose = false; // 両方値がセットされた場合のみ閉じる
      if (from !== null && dateRange.to !== undefined) {
        shouldClose = true;

        if (from.getTime() > dateRange.to.getTime()) {
          return;
        }
      }

      // eslint-disable-next-line no-shadow
      setDateRange((dateRange) => ({
        ...dateRange,
        from: from ?? undefined,
      }));

      if (shouldClose) {
        setOpen(false);
      }
    },
    [dateRange, setDateRange]
  );

  const onChangeTo = useCallback(
    (to: Date | null) => {
      let shouldClose = false; // 両方値がセットされた場合のみ閉じる
      if (to !== null && dateRange.from !== undefined) {
        shouldClose = true;

        if (dateRange.from.getTime() > to.getTime()) {
          return;
        }
      }

      // eslint-disable-next-line no-shadow
      setDateRange((dateRange) => ({
        ...dateRange,
        to: to ?? undefined,
      }));

      if (shouldClose) {
        setOpen(false);
      }
    },
    [dateRange, setDateRange]
  );

  const showsDatePickers = dateRange.value === TermCustom;

  return {
    ...dateRange,
    showsDatePickers,
    open,
    onOpen,
    onClose,
    onSelectType,
    onChangeFrom,
    onChangeTo,
  } as const;
};

export const RentalList: React.FC = () => {
  const [tableLayout, setTableLayout] = useTableLayout(
    'rentalList',
    useMemo(
      () => ({
        managementId: true,
        checkoutAt: true,
      }),
      []
    )
  );

  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const {canEdit: canEditLendingReturn} = useUserResourcesPermissions('LENDING_RETURN');
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');
  const {data: hospitalSettings} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  const [selectedWard, setSelectedWard] = useAtom(rentalHistorySelectedWardStatus);
  const [historyStatus, setHistoryStatus] = useAtom(rentalHistoryMenuStatus);
  const [page, setPage] = useAtom(pageAtom);
  const setOrder = useSetAtom(orderKeyAtom);
  const [searchName, setSearchName] = useAtom(searchNameAtom);

  const variables = useAtomValue(rentalVariablesAtom);
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  const matches = useMediaQuery('(max-width:1079px)');
  const [openDrawer, setOpenDrawer] = useState(false);
  const isDetailFilterActive = useIsDetailFilterActive();
  const [searchFilterResults, setSearchFilterResults] = useAtom(searchFilterResultsAtom);

  const {count: returnWaitingReceptionCount} = useReturnWaitingReceptionsCount(myInfo.hospitalHashId);

  const wardQuery = useQuery([myInfo.hospitalHashId, 'hospitalWards'], () =>
    fetchHospitalWards(myInfo.hospitalHashId, {perPage: 100})
  );

  const wardOptions = useMemo(() => {
    const additionalWards =
      wardQuery.data?.data.map((item) => {
        return {label: item.name, value: item.hashId};
      }) || [];

    return [defaultWard, ...additionalWards];
  }, [wardQuery.data?.data]);

  const deviceLenderSetting = useMemo(() => {
    return (hospitalSettings?.data?.find((x) => x.key === rentalSettings.use_device_lender.key)?.value ??
      'not_need') as HospitalRentalSetting;
  }, [hospitalSettings?.data]);

  const handleActionMenuClick = async (item: MenuItemType) => {
    switch (item.value) {
      case 'export':
        handleExport();
        break;

      case 'changeLayout':
        await handleChangeLayout();
        break;
    }
  };

  const handleHistoryActionMenuClick = (item: MenuItemType) => {
    setPage(1);
    setHistoryStatus(item as RentalHistoryMenuItemType);
  };

  const handleSelectWard = useCallback(
    (item: MenuItemType) => {
      setSelectedWard({
        label: item.label as string,
        value: item.value,
      });
    },
    [setSelectedWard]
  );

  const handleSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    300,
    [variables]
  );

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder({fieldId: tableLayout?.currentLayout[columnIndex].field ?? '', direction: orderDirection});
    },
    [setOrder, tableLayout?.currentLayout]
  );

  useEffect(() => {
    if (wardQuery.isLoading) {
      return;
    }
    if (wardQuery.data?.totalCount === 0) {
      handleSelectWard(defaultWard);
      return;
    } else {
      handleSelectWard(selectedWard);
    }
    // handleSelectWard はdepsに不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardOptions, wardQuery.data, wardQuery.isLoading]);

  const noDataComponent = useMemo(() => {
    if (
      variables.name ||
      variables.checkoutAtFrom ||
      variables.checkoutAtTo ||
      variables.returnedAtFrom ||
      variables.returnedAtTo
    ) {
      return (
        <div>
          <p style={{fontWeight: 'bold'}}>現在の検索条件に一致する貸出・返却はありません。</p>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    } else {
      return (
        <div>
          <p style={{fontWeight: 'bold'}}>貸出・返却はありません。</p>
          <p>ここで機器の貸出と返却履歴を管理します。</p>
        </div>
      );
    }
  }, [
    variables.checkoutAtFrom,
    variables.checkoutAtTo,
    variables.name,
    variables.returnedAtFrom,
    variables.returnedAtTo,
  ]);

  const query = useFetchRentalsQuery(myInfo.hospitalHashId, variables);
  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [query.totalCount, pageSize]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const handleExport = useCallback(async () => {
    try {
      const res = await dialogHandler.open(CreateRentalsDatePickerDialog, {
        title: '貸出・返却履歴のエクスポート範囲を指定',
        description: '貸出日の範囲を設定してください',
        initialStartDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        initialEndDate: dayjs().format('YYYY-MM-DD'),
        startDateLabel: '開始日',
        endDateLabel: '終了日',
      });

      if (!res) return;

      const data: RequestExportRental = {
        CheckoutAtFrom: res.startDate,
        CheckoutAtTo: res.endDate,
        IsContainPastUnreturned: res.isContainPastUnreturned,
      };

      const result = await requestExportRentals(myInfo.hospitalHashId, data);
      if (result.status === 200) {
        openSnackBar('ファイルのエクスポートを受け付けました。処理完了後、通知をご確認ください。', 'center', 'top');
      }
    } catch (_e) {
      openSnackBar('ファイルのエクスポートに失敗しました。', 'center', 'top', 'error');
    }
  }, [myInfo.hospitalHashId]);

  const handleChangeLayout = async () => {
    const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
      tableColumns: tableLayout?.tableLayout ?? [],
      defaultOptions: tableLayout?.currentLayout ?? [],
      forceValue: tableLayout?.forceValue ?? {},
    });
    const newTableLayout = {
      tableLayout: tableLayout?.tableLayout ?? [],
      currentLayout: currentLayout,
    };
    setTableLayout(newTableLayout);
  };

  const handleClickRow = (_event?: React.MouseEvent, rowData?: RentalHistoryIndex) => {
    if (isNullish(rowData)) return;
    navigate(`/products/${rowData.rentalHospitalProduct.hashId}`);
  };

  const handleClickRegistration = () => {
    navigate('/shared');
  };

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<RentalHistoryIndex>[], TableLayout[]>(
      [],
      tableLayout?.currentLayout ?? []
    );
    return tableColumn.map<Column<RentalHistoryIndex>>((item) => {
      switch (item.field) {
        case 'managementId':
          item.render = ({rentalHospitalProduct}: RentalHistoryIndex) => rentalHospitalProduct.managementId;
          break;
        case 'rootCategory':
          item.render = ({rentalHospitalProduct}: RentalHistoryIndex) =>
            CategoryFormatter.getRootCategory(rentalHospitalProduct.categories)?.name || '';
          break;
        case 'narrowCategory':
          item.render = ({rentalHospitalProduct}: RentalHistoryIndex) =>
            CategoryFormatter.getNarrowCategory(rentalHospitalProduct.categories)?.name || '';
          break;
        case 'name':
          item.render = ({rentalHospitalProduct}: RentalHistoryIndex) => rentalHospitalProduct.name;
          break;
        case 'rentalRoom':
          item.render = ({rentalRoom}: RentalHistoryIndex) =>
            rentalRoom ? HospitalRoomFormatter.getFullRoom(rentalRoom) : '未定';
          break;
        case 'displayName':
          item.render = ({rentalHospitalProduct}: RentalHistoryIndex) => rentalHospitalProduct.displayName;
          break;
        case 'checkoutAt':
          item.render = ({checkoutAt}: {checkoutAt: Date}) => dayjs(checkoutAt).format('YYYY/MM/DD HH:mm:ss');
          break;
        case 'returnAt':
          item.render = ({returnAt}: {returnAt: Date}) =>
            returnAt ? dayjs(returnAt).format('YYYY/MM/DD HH:mm:ss') : '';
          break;
        case 'checkoutBy':
          item.render = ({checkoutDeviceLender, checkoutBy}: RentalHistoryIndex) => {
            if (isNullish(checkoutDeviceLender)) return UserFormatter.getFullName(checkoutBy);
            return `${!isNullish(checkoutDeviceLender?.userId) ? checkoutDeviceLender?.userId + ': ' : ''}${
              checkoutDeviceLender?.userName ?? ''
            }`;
          };
          break;
        case 'returnBy':
          item.render = ({returnDeviceLender, returnBy}: RentalHistoryIndex) => {
            if (isNullish(returnDeviceLender)) return UserFormatter.getFullName(returnBy);
            return `${!isNullish(returnDeviceLender?.userId) ? returnDeviceLender?.userId + ': ' : ''}${
              returnDeviceLender?.userName ?? ''
            }`;
          };
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout]);

  const handleBulkReturn = useCallback(
    async (_e: React.MouseEvent, selectedData: RentalHistoryIndex[]) => {
      const res = await dialogHandler.open(BulkReturnRentalProductsDialog, {
        showNotification: historyStatus.value === '',
        lenderSetting: deviceLenderSetting,
      });
      try {
        await bulkUpdateRentals(
          myInfo.hospitalHashId,
          selectedData
            .filter((item) => isNullish(item.returnAt)) // 未返却の機器のみにフィルタ
            .map((item) => ({
              rentalHashId: item.hashId,
              returnUserHashId: myInfo.hashId,
              returnDeviceLender:
                isNullish(res.returnDeviceLenderName) || res.returnDeviceLenderName === ''
                  ? undefined
                  : {
                      userName: res.returnDeviceLenderName,
                    },
            }))
        );
        await query.refetch();
        openSnackBar('返却登録が完了しました。');
      } catch (__e) {
        openSnackBar('返却登録に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [deviceLenderSetting, historyStatus.value, myInfo.hashId, myInfo.hospitalHashId, query]
  );

  const handleBulkMoveTargetBuildingProducts = useCallback(
    async (_e: React.MouseEvent, selectedData: RentalHistoryIndex[]) => {
      const moveProducts = selectedData
        .map((item) => item.rentalHospitalProduct)
        .filter((item) => item.status === 'working');

      const res = await dialogHandler.open(BulkMoveBuildingDialog, {
        defaultUserHashId: myInfo.hashId,
        defaultMovedDate: convertDateToSimpleDateTime(dayjs().toDate()),
      });

      if (moveProducts.length === 0) {
        openSnackBar('選択された機器の中で、転棟可能な機器がありません。', 'left', 'bottom', 'error');
        return;
      }
      try {
        await bulkCreateMoveTargetBuildings(
          myInfo.hospitalHashId,
          moveProducts.map((item) => ({
            hospitalProductHashId: item.hashId,
            moveTargetBuildingAt: convertDateToRFC3339(res.movedAt),
            moveTargetBuildingHashId: res.hospitalRoomHashId,
            registererHashId: res.registererHashId,
          }))
        );
        await query.refetch();
        openSnackBar('転棟処理が完了しました。');
      } catch (__e) {
        openSnackBar('転棟処理に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, query]
  );

  const selectionButtons = useMemo(() => {
    return [
      ...(canEditLendingReturn
        ? [
            {
              label: '一括返却',
              IconComponent: AssignmentReturned,
              onClick: handleBulkReturn,
            },
            {
              label: '転棟',
              IconComponent: MoveTargetBuildingSVG,
              onClick: handleBulkMoveTargetBuildingProducts,
            },
          ]
        : []),
    ];
  }, [handleBulkReturn, handleBulkMoveTargetBuildingProducts]);

  const {data: hospitalRooms, isLoading: isFetchingHospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const hospitalRoomOptions = useMemo(
    () =>
      hospitalRooms.map((room) => ({
        label: HospitalRoomFormatter.getFullRoom(room),
        value: room.hashId,
      })),
    [hospitalRooms]
  );

  const filterDrawerOptions = useMemo((): FilterOption[] => {
    return [
      {
        label: '機器貸出場所（小エリア）',
        value: 'hospitalRoomHashId',
        optionType: 'selector',
        options: hospitalRoomOptions,
      },
    ];
  }, [hospitalRoomOptions]);

  const handleFilterChange = useCallback(
    (results: ResultType[]) => {
      setSearchFilterResults(results);
    },
    [setSearchFilterResults]
  );

  const classes = useStyles();

  return (
    <ListPageLayout page="rentals">
      <ListPageLayout.Header>
        <Grid container>
          <Grid item>
            <Grid container>
              <Grid item>
                <div>
                  <PageRoutingMenu>
                    <Typography id="tutorial-rental-list" variant={'h5'} className={classes.pageTitle}>
                      貸出・返却
                    </Typography>
                    <ArrowDropDown />
                  </PageRoutingMenu>
                </div>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <PopperMenuButton
                      placement="bottom-start"
                      buttonProps={{
                        variant: 'contained',
                        className: classes.rentalHistoryMenu,
                        disableElevation: true,
                      }}
                      itemProps={{style: {minWidth: '400px', fontSize: '14px'}}}
                      menuItemList={rentalHistoryMenuItems}
                      onMenuClick={handleHistoryActionMenuClick}>
                      {historyStatus.label}
                    </PopperMenuButton>
                  </Grid>
                  <Grid item>
                    {wardOptions.length > 0 && (
                      <PopperMenuButton
                        placement="bottom-start"
                        buttonProps={{
                          variant: 'contained',
                          className: classes.buildingMenu,
                          disableElevation: true,
                        }}
                        itemProps={{style: {minWidth: '400px', fontSize: '14px'}}}
                        menuItemList={wardOptions}
                        onMenuClick={handleSelectWard}>
                        {selectedWard.label}
                      </PopperMenuButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.flex} />
          {canEditLendingReturn && (
            <Grid item className={classes.headerLeftButton}>
              <CountOutlinedButton
                onClick={() => navigate('/rentals/return_waiting_reception')}
                count={returnWaitingReceptionCount}>
                返却待ち受付を確認
              </CountOutlinedButton>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleClickRegistration}
                data-testid={'rental-list-navigate-rental-button'}>
                貸出・返却登録
              </Button>
            </Grid>
          )}
        </Grid>
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <TableViewLayout>
          <TableViewLayout.Header container style={{marginTop: '8px'}}>
            <Grid item sm={4} md={3} style={{marginRight: '32px'}}>
              <TextField
                label={'機種名・型式・管理番号で検索'}
                variant={'outlined'}
                fullWidth
                size={'small'}
                InputProps={{
                  endAdornment: <Search />,
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                defaultValue={searchName}
                className={classes.searchText}
                onChange={handleSearchName}
              />
            </Grid>
            <Grid item className={classes.dateSelector}>
              <span className={classes.date}>貸出日時:</span>
              <TermPicker options={TermOptions} {...useTermPicker(checkoutAtAtom)} />
            </Grid>
            <Grid item className={classes.dateSelector}>
              <span className={classes.date}>返却日時:</span>
              <TermPicker
                options={TermOptions}
                dataTestId={'return-period-filter'}
                {...useTermPicker(returnedAtAtom)}
              />
            </Grid>
            {!matches && (
              <Grid item>
                <Button
                  color="inherit"
                  onClick={() => setOpenDrawer(true)}
                  style={{marginLeft: '8px'}}
                  className={isDetailFilterActive || openDrawer ? classes.filterBtnActive : undefined}>
                  <Tune className={classes.actionMenu} />
                  <span className={classes.actionMenu}>詳細で絞り込む</span>
                </Button>
              </Grid>
            )}
            <div className={classes.flex} />
            <Grid item style={{minWidth: '150px'}}>
              <PopperMenuButton
                buttonProps={{variant: 'contained', className: classes.actionBtn, disableElevation: true}}
                menuItemList={actionMenuItems}
                onMenuClick={handleActionMenuClick}>
                アクション
              </PopperMenuButton>
            </Grid>
          </TableViewLayout.Header>
          <TableViewLayout.Body>
            <Table<RentalHistoryIndex>
              stickyHeader={true}
              columns={serializedTableColumn}
              isLoading={query.isLoading}
              data={query.data}
              showSelection={canEditLendingReturn && historyStatus.value === 'renting'}
              selectionButtons={selectionButtons}
              onRowClick={canReadProductList ? handleClickRow : undefined}
              onOrderChange={handleOrderChange}
              noDataComponent={noDataComponent}
              tableSize="small"
            />
          </TableViewLayout.Body>
          <TableViewLayout.Footer container justifyContent="space-between">
            <Grid item className={classes.pageDescription}>
              {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
            </Grid>
            <Grid item className={classes.paginationContainer}>
              <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
              <DisplayNumberSelect
                pageSize={pageSize}
                update={(selectNum) => {
                  setPageSize(selectNum);
                  setPage(1);
                }}
              />
            </Grid>
            <Grid className={classes.emptyGrid} />
          </TableViewLayout.Footer>
        </TableViewLayout>
      </ListPageLayout.Content>

      {!isFetchingHospitalRooms && (
        <FilterDrawer
          open={openDrawer}
          onDrawerClose={() => setOpenDrawer(false)}
          currentCount={query.totalCount}
          filterOptions={filterDrawerOptions}
          onFilterChange={handleFilterChange}
          defaultFilterResults={searchFilterResults}
        />
      )}
    </ListPageLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '32px 24px',
    },
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    divider: {
      margin: '16px 0',
      marginLeft: '-24px',
      width: 'calc(100% + 48px)',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    tableContainer: {
      marginBottom: '24px',
    },
    table: {
      width: '100%',
    },
    statusColor: {
      width: '10px',
      top: '6px',
      marginRight: '8px',
      position: 'relative',
      color: theme.palette.success.main,
    },
    rentalHistoryMenu: {
      width: '216px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
      border: '2px solid #DFE1E5',
    },
    buildingMenu: {
      width: '216px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '8px',
      border: '2px solid #DFE1E5',
      textOverflow: 'ellipsis',
    },
    dateSelector: {
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      marginLeft: '24px',
      marginRight: '21px',
    },
    dateMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
    totalCount: {
      paddingLeft: 8,
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.grey[600],
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    pageDescription: {
      margin: 'auto 0px',
      flex: 1,
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    emptyGrid: {
      flex: 1,
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    headerLeftButton: {
      display: 'flex',
      gap: '16px',
    },
  })
);
