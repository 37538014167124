import React, {CSSProperties} from 'react';
import {NavLink} from 'react-router-dom';
import {theme} from '@atoms/theme';
import {styled, Typography} from '@mui/material';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const defaultText: CSSProperties = {
  padding: '0px',
  fontSize: '14px',
  display: 'inline',
};

const ManagementIdLink = styled(NavLink)({
  ...defaultText,
  color: theme.palette.info.dark,
  fontWeight: 'bold',
  cursor: 'pointer',
  textDecoration: 'none',
});

export const InspectionResultTableManagementIdColumn: React.FC<{
  managementId: string;
  hospitalProductHashId: string;
}> = (props) => {
  const {managementId, hospitalProductHashId} = props;
  const {canRead} = useUserResourcesPermissions('PRODUCT_LIST');

  if (canRead) {
    return <ManagementIdLink to={`/products/${hospitalProductHashId}`}>{managementId}</ManagementIdLink>;
  }

  return (
    <Typography component="span" sx={defaultText}>
      {managementId}
    </Typography>
  );
};
