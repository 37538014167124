import {PermissionLevel, PermissionResource, PERMISSION_RESOURCES} from '../types';
import {useMyInfo} from './useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useHeaderNavLinkItems} from '@Apps/BaseProductManger/useHeaderNavLinkItems';
import {useUserPermissions} from '@modules/auth/hooks/useUserPermissions';
import {HospitalUserPermission} from '@modules/auth/consts';
import {useMemo} from 'react';

type PermissionResult = {
  canRead: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

const checkPermission = (isReadOnly: boolean, canDelete: boolean): PermissionLevel => {
  if (!isReadOnly) {
    return canDelete
      ? PermissionLevel.READ_EDIT_DELETE // 編集・削除可能
      : PermissionLevel.READ_EDIT; // 編集のみ可能
  }

  return canDelete
    ? PermissionLevel.NONE // 削除可能だが読み取り専用(この組み合わせは無いはず)
    : PermissionLevel.READ_ONLY; // 読み取りのみ可能
};

const checkResourcePermission = (resource: PermissionResource, resourceName: string): PermissionLevel => {
  if (resource.resource.name !== resourceName) return PermissionLevel.NONE;
  return checkPermission(resource.isReadOnly, resource.canDelete);
};

/**
 * 第二引数の対象ページ名から権限を返す
 * @param roleResources
 * @param resourceName
 * @returns
 */
const getResourcePermissionLevel = (roleResources: PermissionResource[], resourceName: string): PermissionLevel => {
  const result = roleResources.reduce((maxLevel, resource) => {
    const level = checkResourcePermission(resource, resourceName);
    return Math.max(maxLevel, level);
  }, PermissionLevel.NONE);

  return result;
};

const getPermissionFlags = (level: PermissionLevel): PermissionResult => ({
  canRead: level >= PermissionLevel.READ_ONLY,
  canEdit: level >= PermissionLevel.READ_EDIT,
  canDelete: level >= PermissionLevel.READ_EDIT_DELETE,
});

/**
 * 引数の機能に対するユーザーの権限を判定するカスタムフック。
 *
 * このフックは、ユーザーのロールとリソースの権限情報を取得し、
 * 指定されたリソースに対するユーザーのアクセスレベルを判定します。
 * 判定結果として、`canAccess`, `canRead`, `canEdit`, `canDelete` の4つのフラグを返します。
 *
 * @todo useUserPermissions.tsがすでにあるのでリネームする
 *
 * @param resource - 権限をチェックする対象のリソースのキー。
 *                   `RESOURCES` オブジェクトのキーである必要があります。
 *
 * @returns {PermissionResult} 指定されたリソースに対するユーザーの権限を示すフラグを含むオブジェクト:
 * - `canRead`: ユーザーがページを表示する権限を持つ場合に `true`
 * - `canEdit`: ユーザーがページ内の何項目を編集する権限を持つ場合に `true`
 * - `canDelete`: ユーザーがページ内の項目をを削除する権限を持つ場合に `true`
 *
 */
export const useUserResourcesPermissions = (resource: keyof typeof PERMISSION_RESOURCES): PermissionResult => {
  const {myResource} = useMyInfo();
  const {isAdmin, isReadOnly, isRentalOnly, isWard} = useMyRole();

  // NOTE:フューチャーフラグが無い時はuseMyRoleの値で判別
  if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    // 貸出・返却（専用画面）の判別時に貸出ユーザーであれば権限を与える
    if (isRentalOnly) {
      if (resource !== 'LENDING_RETURN_RECEPTION') {
        return getPermissionFlags(PermissionLevel.NONE);
      }
      return getPermissionFlags(PermissionLevel.READ_EDIT_DELETE);
    }
    // 病棟メニューの判別時に病棟メニューユーザーであれば権限を与える
    if (isWard) {
      if (resource !== 'WARD_SERVICE') {
        return getPermissionFlags(PermissionLevel.NONE);
      }
      return getPermissionFlags(PermissionLevel.READ_EDIT_DELETE);
    }

    return getPermissionFlags(checkPermission(isReadOnly, isAdmin));
  }

  if (!myResource) return getPermissionFlags(PermissionLevel.NONE);

  const resourceKey = PERMISSION_RESOURCES[resource];
  if (!resourceKey) return getPermissionFlags(PermissionLevel.NONE);

  const level = getResourcePermissionLevel(myResource, resourceKey.id);
  return getPermissionFlags(level);
};

/**
 * チャンネル一覧の読み取り権限
 * 既存実装がuseAuthzから取得しており、ローカル環境では判別できないため
 * @todo カスタム内部権限がリリース後は削除しuseUserResourcesPermissions('CHANNEL_LIST');で判別
 * @returns
 */
export const useCanReadChannelList = () => {
  const {isPermitted} = useUserPermissions();
  const hasPermitted = isPermitted([HospitalUserPermission.ChannelManagement]);
  const {canRead} = useUserResourcesPermissions('CHANNEL_LIST');
  const canReadChannelList = useMemo(() => {
    if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
      return hasPermitted;
    }
    return canRead;
  }, [isPermitted, canRead]);
  return canReadChannelList;
};

/**
 * Asset貸出返却専用ユーザーであればtrueを返す
 */
export const isRentalOnlyUser = () => {
  const {isRentalOnly} = useMyRole();

  if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    return isRentalOnly;
  }
  const accessibleResources = useAccessibleResources();
  return (
    accessibleResources.length === 1 && accessibleResources.some((v) => v.resource.name === 'lending_return_reception')
  );
};

/**
 * Asset病棟ユーザーであればtrueを返す
 */
export const isWardOnlyUser = () => {
  const {isWard} = useMyRole();

  if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    return isWard;
  }
  const accessibleResources = useAccessibleResources();
  return accessibleResources.length === 1 && accessibleResources.some((v) => v.resource.name === 'ward_service');
};

/**
 * 経営ダッシュボードにアクセスできるか
 * @returns
 */
export const useCanAccessMgmtDashboard = () => {
  const {myInfo} = useMyInfo();
  const {canRead: canReadManagementDashboard} = useUserResourcesPermissions('MANAGEMENT_DASHBOARD');
  const {isPermitted} = useUserPermissions();
  const hasPermitted = isPermitted([HospitalUserPermission.ReadManagementDashboard]);
  // フューチャーフラグ無しの時はuseAuthzのpermission情報を見る
  if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    return hasPermitted;
  }

  return myInfo.canAccessMgmtDashboard && canReadManagementDashboard;
};

/**
 * ユーザーがアクセス可能なリソースを返す関数
 *
 * @param roleResources - 現在のユーザーに紐づいたリソース情報のリスト
 * @returns {ResourceItem[]} ユーザーがアクセス可能なリソースのリスト
 */
export const useAccessibleResources = (): PermissionResource[] => {
  const {myResource} = useMyInfo();
  // NOTE:フューチャーフラグが無い時はすべてのアクセス可能な値を渡すことで既存と同じ動作としている
  if (!FEATURE_CUSTOM_ASSET_ROLE_FLAG) return myResource?.filter((v) => v.resource.name !== 'login') ?? [];

  if (!myResource) return [];

  // FIXME:APIからArray内に同じ値が返ってきたことがあるのでユニークになるようにまとめる
  const uniqueItemsMap = new Map<string, PermissionResource>();
  for (const item of myResource) {
    if (!uniqueItemsMap.has(item.resource.hashId)) {
      uniqueItemsMap.set(item.resource.hashId, item);
    }
  }

  const uniqueItemsList = Array.from(uniqueItemsMap.values());

  const accessibleResources = uniqueItemsList?.filter(
    (v) => v.resource.name !== 'login' && checkPermission(v.isReadOnly, v.canDelete) !== PermissionLevel.NONE
  );

  return accessibleResources ?? [];
};

/**
 * 権限の無いページのリダイレクト用として権限のあるページのパスを返す
 * @returns
 */
export const usePermittedRedirectPath = () => {
  const isWard = isWardOnlyUser();
  const isRental = isRentalOnlyUser();
  const defaultRedirectPath = isWard ? '/shared/reception_menu' : isRental ? '/shared' : '/account/profile';
  const headerNavLinkItem = useHeaderNavLinkItems();
  return headerNavLinkItem.find((v) => v.hasPermission)?.to || defaultRedirectPath;
};
