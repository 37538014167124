import {useHeaderNavLinkItems} from '@Apps/BaseProductManger/useHeaderNavLinkItems';
import {Header} from '@components/organisms/Header/pc';
import {withSuspense} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {Grid, makeStyles, Theme} from '@material-ui/core';
import {useLogout} from '@modules/auth/hooks';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useTemplate} from '@templates/NoSideBarLayoutForPC';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
}));

const isMobilePath = (pathname: string) => {
  return pathname.indexOf('/sp') === 0;
};

type BaseProductManagerProps = {
  showHeader: boolean;
  children?: React.ReactNode;
};

const _BaseProductManager = ({children, showHeader}: BaseProductManagerProps) => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const logout = useLogout();
  const {myInfo} = useMyInfo();
  // TODO:useMyRole置き換え
  const {isAdmin, canAccessAsset} = useMyRole();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hasInitialSettingAlerted = sessionStorage.getItem('hasInitialSettingAlerted');
    if (isMobileOnly && !isMobilePath(location.pathname)) {
      navigate('/sp/products', {replace: true});
      return;
    }

    if (!isNullish(data) && !data.assetInitialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    // 初期設定が済んでいないユーザーは別システムへ遷移
    if (!isNullish(myInfo?.initialized) && !myInfo.initialized && !isMobileOnly) {
      if (!hasInitialSettingAlerted) {
        sessionStorage.setItem('hasInitialSettingAlerted', 'true');
        window.alert(`ユーザー情報の初回設定のため、HITOTSU Hospitalに遷移します`);
      }
      window.location.href = import.meta.env.VITE_ACCOUNT_SERVICE_URL;
      return;
    }

    if (!canAccessAsset) {
      alert('このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。');
      logout();
    }
  }, [canAccessAsset, data, location.pathname, logout, myInfo, navigate]);

  return (
    <Grid container className={clsx(templateClasses.root, classes.root)}>
      {showHeader && <Header className={templateClasses.header} canSetting={isAdmin} />}
      <Grid container className={showHeader ? templateClasses.content : templateClasses.noHeaderContent}>
        {children}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export const BaseProductManager = withSuspense(_BaseProductManager, null);
